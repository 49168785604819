:root {
  --colorText: #1273b7;
  --font: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;

  --colorBlack: #1c1c24;
  --colorDarkGray: #36373b;
  --colorGray: #38393d;
  --colorLightGray: #434343;
  --colorOrange: #f7612c;
  --colorBrown: #3a2426;
  --fontTextSize: 22px;
  --titelSize: 2.25rem;
  --titelColor: white;
  --colorPink: #f6212c;
  --titelFontWeght: 500;
  --line-height: 1.5;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}
.wrapper {
  max-width: 70rem;
  margin-inline: auto;
  padding-inline: 2rem;
}
.section_title {
  font-size: var(--titelSize);
  font-family: var(--font);
  color: var(--lightBegh);
  font-weight: var(--titelFontWeght);
}
section {
  scroll-margin-top: 1em;
}
body {
  background-color: var(--colorBlack);
}

.App {
  /* background-color: black; */
  text-align: center;
}

@media (min-width: 501px) {
  .burger {
    display: none;
  }
  .header {
    position: sticky;
    top: 0;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: var(--colorBlack);
  }

  .menu a {
    list-style: none;
    display: flex;
    /* gap: 20px; */
    color: white;
    font-family: var(--font);
  }
  .menu {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    gap: 20px;
    list-style-type: none;
  }

  .logo {
    color: white;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-weight: 800;
    font-size: 40px;
    padding-left: 32px;
  }
}

@media (max-width: 500px) {
  .burger {
    display: block;
    color: var(--colorPink);
    font-size: 25px;
    /* margin-right: 15px; */
  }

  .menu {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    position: absolute;
    top: 43px; /* Adjust as needed based on your design */
    left: 0;
    /* background-color: black;  */
    width: 100%;
    text-align: center;
    box-shadow: 0 0 10px black(0, 0, 0, 0.1);
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    color: aliceblue;
  }
  .menu.open a {
    color: white;
  }
  .menu.open {
    transform: translateX(0);
    gap: 13px;
    display: flex;
    justify-content: flex-start;
    margin: 20px;
  }
  /* .menu.open ul {
    background: var(--colorBlack);
  } */
  .burger.open {
    font-size: 25px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px;
    position: sticky;
    top: 0;
    background: var(--colorBlack);
    z-index: 5;
  }
  .logo {
    color: white;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-weight: 700;
    font-size: 30px;
  }
}

.first_section {
  position: relative;
  background-color: var(--colorBlack);
  height: 80vh; /* Adjust as needed based on your design */
  z-index: -8;
}

.first_section::before,
.first_section::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.first_section::before {
  background: linear-gradient(
    15deg,
    rgba(28, 28, 36, 1) 37%,
    rgba(246, 33, 44, 1) 78%
  );
  /* clip-path: polygon(0% 0%, 100% 0%, 0% 100%); */
  clip-path: polygon(40% 60%, 100% 0%, 100% 100%);
  z-index: -1;
  transition: filter 1s ease;
  animation: fade 16s ease-in-out 1s infinite;
}

.first_section::after {
  background: url("/public/pictures/1.jpg") center/cover no-repeat; /* Replace 'path-to-your-image.jpg' with the actual path to your image */
  clip-path: polygon(0% 10%, 500% 100%, 0% 900%);
  z-index: -2;
  animation: image_fade 15s ease-in-out infinite;
}
@keyframes image_fade {
  0%,
  12.5% {
    opacity: 1;
  }
  25%,
  37.5% {
    opacity: 0;
    background: url("/public/pictures/2.jpg") center/cover no-repeat;
  }
  50%,
  62.5% {
    opacity: 1;
  }
  75%,
  87.5% {
    opacity: 0;
    background: url("/public/pictures/3.jpg") center/cover no-repeat;
  }
  100% {
    opacity: 1;
    background: url("/public/pictures/4.jpg") center/cover no-repeat;
  }
}

.image-container img {
  position: absolute;
  top: 199px;
  right: 295px;
  object-fit: cover;
  opacity: 1; /* Initial opacity value */
  z-index: 3;
}
@media (max-width: 500px) {
  .image-container img {
    position: absolute;
    top: 92px;
    right: 150px;
    object-fit: cover;
    opacity: 1;
    z-index: 3;
  }
  .slogan {
    position: absolute;
    top: 393px;
    /* right: 88px; */
    object-fit: cover;
    font-size: 40px;
    z-index: 8;
    color: white;
    font-weight: 800;
    justify-content: center;
    text-align: center;
    display: flex;
    align-items: center;
    margin: 10px;
    animation: fadeIn 3s infinite;
  }
}
@media (min-width: 501px) {
  .slogan {
    position: absolute;
    top: 578px;
    right: 66px;
    object-fit: cover;
    font-size: 50px;
    z-index: 2;
    color: white;
    font-weight: 800;
  }
}
.circle-container {
  position: relative;
}

.circle {
  position: absolute;
  border-radius: 50%;
  border: 3px dotted red;
  background-color: transparent;
  animation-duration: 1s;
  box-shadow: 5px 5px 10px #888888, -5px -5px 10px #888888;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 15;
  opacity: 0;
}

.first-circle {
  width: 100px;
  height: 100px;
  top: 199px;
  right: 295px;
  animation: fade 3s ease-in-out 1s infinite;
}

.second-circle {
  width: 150px;
  height: 150px;
  top: 234px;
  right: 320px;
  animation: fade 3s ease-in-out 2s infinite;
}

.third-circle {
  width: 200px;
  height: 200px;
  top: 259px;
  right: 345px;
  animation: fade 3s ease-in-out 3s infinite;
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
/* .first_section:hover .image-container img,
.first_section:hover::before,
.first_section:hover .circle {
  opacity: 0;
  transition: opacity 0.5s ease-in-out; /* Add a transition for smooth fading */

.rotatingText {
  align-items: center;
  display: flex;
  height: 75vh;
  text-align: center;
  z-index: 9;
  position: relative;
}

.rotatingText-content {
  position: relative;
  width: 100%;
}

.rotatingText-description {
  font-family: Arial, Helvetica, sans-serif;

  font-weight: 700;
  margin: 0;
  color: white;

  @media (min-width: 768px) {
    font-size: 18px;
  }
}

.rotatingText-line {
  font-family: "Open Sans", sans-serif;
  font-size: 40px;
  left: 0;
  margin-bottom: 0;
  margin-top: 30px;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  color: var(--colorOrange);
  .rotatingText-line {
    /* ... existing styles ... */
    animation-duration: 3s; /* Set the duration to 3 seconds */
    animation-iteration-count: 3; /* Repeat the animation 3 times */
  }

  @media (min-width: 768px) {
    font-size: 70px;
  }

  &:nth-of-type(1) {
    animation: rotate-text-up 1.5s 0.75s;
  }

  &:nth-of-type(2) {
    animation: rotate-text-up 2.5s 2s;
  }

  &:nth-of-type(3) {
    animation: fade-text-in 3.5s 3.25s forwards;
  }
}

@keyframes rotate-text-up {
  0% {
    transform: translate3d(0, 80px, 0);
    opacity: 0;
  }

  20%,
  80% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(0, -40px, 0);
    opacity: 0;
  }
}

@keyframes fade-text-in {
  0% {
    opacity: 0;
    transform: translate3d(0, 80px, 0);
  }

  50%,
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
/* section {
  height: 80vh;
}
.tag {
  opacity: 0;
  transform: translate(0, 10vh);
  transition: all 1s;
}
.tag.visible {
  opacity: 1;
  transform: translate(0, 0);
}
.yellow {
  background-color: lightyellow;
}
.red {
  background-color: lightcoral;
}
.blue {
  background-color: lightblue;
}
.green {
  background-color: lightgreen;
} */
@media (min-width: 501px) {
  .classes_section {
    background: radial-gradient(
      circle,
      rgba(246, 33, 44, 1) 40%,
      rgba(28, 28, 36, 1) 88%
    );
  }
}
@media (max-width: 500px) {
  .classes_section {
    background: radial-gradient(
      circle,
      rgba(246, 33, 44, 0.9836309523809523) 58%,
      rgba(28, 28, 36, 1) 100%
    );
  }
}
.women_classes p {
  max-width: 400px;
}
.women_classes {
  margin: 10px;
}
.men_classes {
  margin: 10px;
}

.women_classes h3 {
  color: white;
}
.men_classes p {
  max-width: 400px;
}

.men_classes h3 {
  color: white;
}

@media (min-width: 501px) {
  .wrapper_classes_section {
    max-width: 70rem;
    margin-inline: auto;
    padding-inline: 2rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}
@media (max-width: 500px) {
  .wrapper_classes_section {
    max-width: 70rem;
    margin-inline: auto;
    padding-inline: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.contactUsButton {
  align-items: center;
  appearance: none;
  background-clip: padding-box;
  background-color: initial;
  background-image: none;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  flex-direction: row;
  flex-shrink: 0;
  font-family: Eina01, sans-serif;
  font-size: 16px;
  font-weight: 800;
  justify-content: center;
  line-height: 24px;
  margin: 0;
  min-height: 64px;
  outline: none;
  overflow: visible;
  padding: 19px 26px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: auto;
  word-break: keep-all;
  z-index: 0;
}

@media (min-width: 768px) {
  .contactUsButton {
    padding: 19px 32px;
  }
}

.contactUsButton:before,
.contactUsButton:after {
  border-radius: 80px;
}

.contactUsButton:before {
  background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
}

.contactUsButton:after {
  background-color: initial;
  background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
  bottom: 4px;
  content: "";
  display: block;
  left: 4px;
  overflow: hidden;
  position: absolute;
  right: 4px;
  top: 4px;
  transition: all 100ms ease-out;
  z-index: -1;
}

.contactUsButton:hover:not(:disabled):before {
  background: linear-gradient(
    92.83deg,
    rgb(255, 116, 38) 0%,
    rgb(249, 58, 19) 100%
  );
}

.contactUsButton:hover:not(:disabled):after {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition-timing-function: ease-in;
  opacity: 0;
}

.contactUsButton:active:not(:disabled) {
  color: #ccc;
}

.contactUsButton:active:not(:disabled):before {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
}

.contactUsButton:active:not(:disabled):after {
  background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
  bottom: 4px;
  left: 4px;
  right: 4px;
  top: 4px;
}

.contactUsButton:disabled {
  cursor: default;
  opacity: 0.24;
}

@media (min-width: 501px) {
  .wrapper_about_time {
    top: 30%;
    bottom: 50%;
    z-index: 1;
    position: relative;
    max-width: 70rem;
    margin-inline: auto;
    padding-inline: 2rem;
  }
  .about_time_section {
    position: relative;
    width: 100%;
    /* max-height: 550px; */

    height: 60vh;
    background: url("/public/pictures/2.jpg");
    background-size: cover;
    background-position: center;
  }

  .about_time_section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(
      66,
      64,
      64,
      0.7
    ); /* Red background with 50% opacity */
  }
}
@media (max-width: 500px) {
  .wrapper_about_time {
    top: 6%;
    z-index: 1;
    position: relative;
    max-width: 70rem;
    margin-inline: auto;
    padding-inline: 2rem;
  }
  .about_time_section {
    position: relative;
    width: 100%;
    max-height: 660px;
    height: auto;
    background: url("/public/pictures/2.jpg") center/cover no-repeat;
  }

  .about_time_section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(
      66,
      64,
      64,
      0.8
    ); /* Red background with 50% opacity */
  }
  .red_title {
    color: var(--colorPink);
    margin-top: 0;
    padding: 30px;
  }
}
@media (min-width: 501px) {
  .about_section {
    position: relative;
    width: 100%;
    height: 30vh;
    background: url("/public/pictures/1.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
  }

  .about_section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(
      255,
      0,
      0,
      0.5
    ); /* Red background with 50% opacity */
  }
  .red_title {
    color: var(--colorPink);
    margin-top: 0;
    padding: 30px;
  }
  .wrapper_about {
    position: relative;
    z-index: 1;
    height: auto;
    max-width: 70rem;
    margin-inline: auto;
    padding-inline: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .wrapper_about ul {
    display: flex;
    text-align: left;
    justify-content: left;
    flex-direction: column;
  }
  .title {
    padding: 20px;
    margin-top: 0;
    color: white;
    font-size: 30px;
    margin-block-end: 0;
  }
  strong {
    color: #1c1c24;
    font-weight: 800;
  }
}
@media (max-width: 500px) {
  .about_section {
    position: relative;
    width: 100%;
    max-height: 560px;
    background: url("/public/pictures/1.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
  }
  strong {
    color: #1c1c24;
    font-weight: 800;
  }
  .about_section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(
      255,
      0,
      0,
      0.5
    ); /* Red background with 50% opacity */
  }
  .title {
    padding: 20px;
    margin-top: 0;
    color: white;
    font-size: 30px;
    margin-block-end: 0;
  }
  .wrapper_about {
    position: relative;
    z-index: 1;
    height: auto;
    max-width: 70rem;
    margin-inline: auto;
    padding-inline: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .wrapper_about ul {
    display: flex;
    text-align: left;
    justify-content: left;
    flex-direction: column;
  }
}
@media screen and (min-width: 501px) {
  .footer_conteiner {
    display: flex;
    justify-content: space-between;
    background: var(--colorDarkGray);
    padding: 20px;
  }
}
@media screen and (max-width: 500px) {
  .footer_conteiner {
    display: block;
    justify-content: space-between;
    background: var(--colorDarkGray);
    padding: 10px;
    margin-inline-start: "0";
  }
  .company_name {
    display: flex;
    text-align: center;
    justify-content: center;
  }
}

.footer div {
  color: var(--light);
}
.contact {
  display: flex;
  flex-direction: column;
  /* color: var(--light); */
}
.footer_company_name {
  color: var(--colorPink);
  font-size: var(--titelSize);
  font-weight: var(--titelFontWeght);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 10px;
}
.footer_sub_container p {
  display: flex;
  justify-content: left;
  max-width: 350px;
  text-align: start;
  padding-inline-start: 20px;
  color: white;
}
.footer_sub_container {
  text-align: start;
}
.contact_info {
  font-size: larger;
  font-weight: var(--titelFontWeght);
}
.footer_icon {
  font-size: 30px;
  color: var(--colorPink);
}
.footer_icon:hover {
  transition: scale(1);
  color: var(--colorOrange);
}
.footer_icons {
  padding: 10px;
  display: flex;
  justify-content: space-around;
}
