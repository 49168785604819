.auto-trainers-section {
  display: flex;

  flex-direction: column;
}
@media (min-width: 501px) {
  .wrapper_trainers_section {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
  }
}
@media (max-width: 500px) {
  .wrapper_trainers_section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.card {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  width: 250px;
  text-align: center;
  margin: 10px;
}

.card img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-bottom: 1px solid #ddd;
}

.card-content {
  padding: 20px;
}

.trainer-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #f6212c;
}

.trainer-description {
  font-size: 14px;
  color: #3a2426;
}
.section_title {
  color: #f7612c;
}
